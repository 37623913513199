import './jquery.visible.min';
var maxHeight = 0;
var winHeight = 0;
var winWidth = 0;
var mainTop = 0;
var lightFieldHeightOrg = 0;
var headertop = null;
var header = null;
var headerHeightOrg = 0;
var headerObj = null;
var headerLogo = null;
var headerLogoHeightOrg = 0;
var headertopHeightOrg = 0;
var headerHeightOrg = 0;

$(document).ready(function() {
    header = document.getElementById('header');
    headertop = document.getElementById('headertop');
    headerLogo = document.getElementById('headerlogo');
    winHeight = $(window).height();
    winWidth = $(window).width();
    mainTop = parseInt($('#main').css('padding-top'));
    initAppartementUebersicht(true);
    setTimeout(function() {
        initContent();
    }, 100);
});

function initContent() {
    var iconname = '';
    var pfad = '';
    maxHeight = document.body.clientHeight;
    $('.fontawsome-icon').each(function() {
        iconname = $(this).data('iconname');
        pfad = $(this).children().attr('src');
        $(this).load(pfad);
    });
    headertopHeightOrg = parseInt(getComputedStyle(headertop).height);
    headerHeightOrg = parseInt(getComputedStyle(header).height);
    headerLogoHeightOrg = parseInt(getComputedStyle(headerLogo).height);
    parallax();
    $(window).on('scroll', function() {
        parallax();
        if(winWidth > 576)
            aniMenue();
        aniIlu();
    });
}

function aniIlu() {
    var offset = $(window).scrollTop() + $(window).height();

    if(offset >= ($('body').height() - 35)) {
        $('.ilu').addClass('visible');
    }
}

function aniMenue() {

    var scrollTop = window.scrollY || window.scrollTop || 0;

    var headerHeightNew = headerHeightOrg - (parseInt(scrollTop) / 8);
    var headertopHeightNew = headertopHeightOrg - (parseInt(scrollTop) / 8);
    var headerLogoNew = headerLogoHeightOrg - (parseInt(scrollTop) / 6);


    if(winWidth > 1024) {
        if(headerHeightNew < 130)
            headerHeightNew = 130;

        if(headertopHeightNew < 70)
            headertopHeightNew = 70;

        if(headerLogoNew < 65)
            headerLogoNew = 65;

        headertop.style.height = headertopHeightNew + 'px';
        header.style.height = headerHeightNew + 'px';
        headerLogo.style.maxHeight = headerLogoNew + 'px';
    } else {
        if(headerHeightNew < 70)
            headerHeightNew = 70;
        if(headerHeightNew > 100)
            headerHeightNew = 100;

        if(headertopHeightNew < 70)
            headertopHeightNew = 70;
        if(headertopHeightNew > 100)
            headertopHeightNew = 100;

        if(headerLogoNew < 100)
            headerLogoNew = 100;
        if(headerLogoNew < 65)
            headerLogoNew = 65;

        headertop.style.height = headertopHeightNew + 'px';
        header.style.height = headerHeightNew + 'px';
        headerLogo.style.maxHeight = headerLogoNew + 'px';
    }
}

function parallax() {
    var objekte = document.getElementsByClassName('parallax-video');

    var i = 0;
    var beforeOverlayed = false;
    var elemente = [];

    Array.prototype.forEach.call(objekte, function (el) {
        var pic = el.firstElementChild;
        var winTop = el.getBoundingClientRect().top;
        var moveTop = 0;
        if(0 > (winTop - winHeight)) {
            moveTop = (winTop) / 2 * -1;
        }

        console.log(winTop);

        // pic.style.bottom = '0px';

        var picdata = getComputedStyle(pic);
        var picturedata = getComputedStyle(pic);

        var tmp1 = parseInt(parseInt(picdata.height) - moveTop);
        var tmp2 = parseInt(picturedata.height);
        pic.dataset.tmp1 = tmp1;
        pic.dataset.tmp2 = tmp2;
        if(tmp1 < tmp2)
            pic.style.transform = 'translate3d(0px, ' + moveTop + 'px, 0px)';

        i++;
    });

    $('.parallax').each(function() {
        var $elem = $(this);

        // if($elem.isVisible()) {
            var parent_top = $elem.offset().top;
            var window_bottom = $(window).scrollTop();
            var oVal = ((window_bottom - parent_top) / 3);

            if ($elem.parent().hasClass('teaser--halb')) {
                // oVal = ((window_bottom - parent_top) / 3);
            }

            var tmp = parseInt($elem.height()) * -1;
            if(tmp > oVal)
                oVal = tmp;

            $elem.children().css('transform', 'translate3d(0px, ' + oVal + 'px, 0px)');
        // }
    });
}

var wasClicked = false;
function initAppartementUebersicht(first = false) {
    if(wasClicked !== false)
        return false;

    if(first === true) {
        $('.bilder--slider').children().each(function(index) {
            $(this).data('picindex', index);
        });
    }

    if(first !== true && $('.bilder--slider').visible(true)) {
        $('.bilder--slider').each(function (index) {
            var obj = $(this);
            obj.css('height', (parseInt(obj.css('width')) / 16 * 9 ) + 'px');
            var fertig = false;
            obj.children().each(function (index2) {
                var aktuell = $(this);
                if (aktuell.hasClass('active') === true && fertig === false) {
                    if (aktuell.next().length) {
                        aktuell.removeClass('active');
                        aktuell.next().addClass('active');
                        fertig = true;
                    } else {
                        aktuell.removeClass('active');
                        aktuell.parent().children(':first').addClass('active');
                        fertig = true;
                    }
                }
            });
        });
        $('.bilder-slider-preview-btn').each(function (index) {
            var obj = $(this);

            var fertig = false;
            obj.children().each(function (index2) {
                var aktuell = $(this);
                if (aktuell.hasClass('active') === true && fertig === false) {
                    if (aktuell.next().length) {
                        aktuell.removeClass('active');
                        aktuell.next().addClass('active');
                        fertig = true;
                    } else {
                        aktuell.removeClass('active');
                        aktuell.parent().children(':first').addClass('active');
                        fertig = true;
                    }
                }
            });
        });
    }

    setTimeout(function() {
        if(wasClicked === false) {
            initAppartementUebersicht(false);
        } else {
            wasClicked = false;
            setTimeout(function() {
                initAppartementUebersicht(false);
            }, 5000);
        }
    }, 5000);
}